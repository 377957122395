<template>
    <el-form ref="driverform" :model="driverInfo" :rules="rules" class="management-content"
             label-position="left">
        <el-row>
            <table class="management-table">
                <thead>
                <td colspan="3" class="management-content-title">{{$t('driver.driverInformation')}}</td>
                </thead>
                <tr>
                    <td>
                        <el-form-item prop="FirstName" :label="$t('driver.firstName')" :label-width="width.column1">
                            <el-input v-model.trim="driverInfo.FirstName"
                                      size="small"
                                      class="text-value-input"
                                      @input.native="FirstNameInputNoSpace()"
                            ></el-input>
                        </el-form-item>
                    </td>
                    <td>
                        <el-form-item prop="LastName" :label="$t('driver.lastName')" :label-width="width.column2">
                            <el-input v-model.trim="driverInfo.LastName" size="small"
                                      class="text-value-input" @input.native="lastNameInputNoSpace"></el-input>
                        </el-form-item>
                    </td>
                    <td>
                        <el-form-item prop="CellPhone" :label="$t('driver.contactNumber')" :label-width="width.column3">
                            <el-input v-model="driverInfo.CellPhone" size="small" class="text-value-input"></el-input>
                        </el-form-item>
                    </td>

                </tr>
                <tr>
                    <td>
                        <el-form-item :label="$t('driver.dateJoined')" :label-width="width.column1">
                            <el-date-picker
                                    v-model="driverInfo.DateJoined"
                                    type="date"
                                    size="small"
                                    class="text-value-input date-joined"
                                    :placeholder="$t('tips.vehicles.select')"
                                    :default-value="new Date()"
                                    format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>

                    </td>
                    <td>
                        <el-form-item prop="Contact" :label="$t('driver.emergContact')" :label-width="width.column2">
                            <el-input v-model="driverInfo.Contact" size="small"
                                      class="text-value-input"></el-input>
                        </el-form-item>
                    </td>
                    <td>
                        <el-form-item prop="ContactCellPhone" :label="$t('driver.emergContactNumber')"
                                      :label-width="width.column3">
                            <el-input v-model="driverInfo.ContactCellPhone" size="small"
                                      class="text-value-input"></el-input>
                        </el-form-item>
                    </td>

                </tr>
                <tr>
                    <td>
                        <el-form-item prop="License" :label="$t('driver.licenseNumber')" :label-width="width.column1">
                            <el-input v-model="driverInfo.License" size="small" class="text-value-input"></el-input>
                        </el-form-item>
                    </td>
                    <td>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="3">
                        <el-form-item class="license-area" :label-width="width.column1">
                            <div slot="label" style="padding-top: 35px">{{$t('driver.driverLicense')}}</div>
                            <v-upload :file="getImagePath(driverInfo.LicensePictures)"
                                      @selectFile="selectLicense"></v-upload>
                        </el-form-item>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <el-form-item class="license-area" :label-width="width.column1">
                            <div slot="label" style="padding-top: 40px">{{$t('driver.driverPhoto')}}</div>
                            <v-upload :file="getImagePath(driverInfo.OtherPictures)"
                                      :showOtherFile="isEditMode"
                                      :otherFile="getHttpPath(driverInfo.FRPictures)"
                                      align="vertical"
                                      @selectFile="selectDriver"
                            ></v-upload>

                        </el-form-item>
                    </td>
                </tr>

                <tr>
                    <td colspan="3">
                        <el-form-item class="" label-width="165px">
                            <div slot="label" style="padding-top: 40px">{{$t('driver.permittedVehicles')}}</div>
                            <v-vehicle-multi-select v-model="driverInfo.Vehicles"></v-vehicle-multi-select>
                        </el-form-item>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <el-form-item :label="$t('driver.comment')" prop="Remark" :label-width="width.column1">
                            <el-input v-model="driverInfo.Remark" size="small" type="textarea" :rows="5"
                                      :maxlength="100" show-word-limit
                                      style="width: 600px"></el-input>
                        </el-form-item>
                    </td>
                </tr>
            </table>

            <table v-if="isEditMode" class="management-table">
                <thead>
                <td colspan="2" class="management-content-title">{{$t('driver.mobile360APPDriverLoginInformation')}}
                </td>
                </thead>
                <tr>
                    <td>
                        <el-form-item :label="$t('driver.loginID')" prop="LoginName">
                            <el-input v-model.trim="driverInfo.LoginName" size="small" class="loginId-input"
                                      @input.native="loginIDInputNoSpace">
                                <template slot="prepend"><span>{{driverInfo.CompanyPrefix}}</span></template>
                            </el-input>
                        </el-form-item>
                    </td>
                    <td>
                        <el-form-item :label="$t('driver.password')" prop="Password">
                            <el-input v-model="password" size="small" disabled style="width: 200px">
                            </el-input>
                        </el-form-item>
                    </td>
                </tr>

            </table>
        </el-row>
        <div class="button-row" style="margin-top: 20px">
            <el-button size="small" class="ok-button" style="width: 150px" @click="onSave" :loading="isSaving">{{$t('globalText.save')}}
            </el-button>
            <el-button size="small" class="cancel-button" @click="onCancle">{{$t('globalText.cancel')}}</el-button>
        </div>
    </el-form>
</template>

<script>
    import vUpload from '../../../components/common/Upload'
    import vVehicleMultiSelect from '../../../components/management/VehicleMultSelect'
    import {getArr1DifferenceFrommArr2} from "../../../assets/js/Util";
    import regExp from "../../../assets/js/RegExp"

    export default {
        name: "AddOrEditDriver",
        components: {
            vVehicleMultiSelect,
            vUpload
        },
        data() {
            return {
                isSaving: false,
                isEditMode: false,
                driverInfo: {
                    Id: "",
                    FirstName: "",
                    LastName: "",
                    License: null,
                    DateJoined: new Date(),
                    CellPhone: null,
                    Contact: null,
                    ContactCellPhone: null,
                    Remark: null,
                    LicensePictures: null,
                    OtherPictures: null,
                    FRPictures: null,
                    LicensePicturesRemove: [],
                    OtherPicturesRemove: [],
                    LicensePictureBase64: null,
                    DriverPhotoBase64: null,
                    Vehicles: []
                },
                rules: {
                    FirstName: [
                        {
                            required: true,
                            message: this.$t('tips.drivers.pleaseInputAFirstName'), // 'Please input a first name',
                            trigger: ['change', 'blur']
                        },
                        {
                            min: 1,
                            max: 15,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 15}), //'A maximum of 15 characters can be used',
                            trigger: ['change', 'blur']
                        },
                    ],
                    LastName: [
                        {
                            required: true,
                            message: this.$t('tips.drivers.pleaseInputALastName'), //'Please input a last name',
                            trigger: ['change', 'blur']
                        },
                        {
                            min: 1,
                            max: 15,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 15}), // 'A maximum of 15 characters can be used',
                            trigger: ['change', 'blur']
                        },
                        // {validator: checkName, trigger: 'blur'}
                    ],
                    CellPhone: [
                        // {required: true, message: 'Please input a contact number', trigger: 'blur'},
                        {
                            min: 6, max: 32,
                            message: this.$t('globalText.mustContainBetween_X_To_Y_Characters', {min: 6, max: 32}), // 'Must contain between 6 to 32 characters',
                            trigger: 'blur'
                        },
                        {validator: regExp.checkPhoneNumber, trigger: ['change', 'blur']}
                    ],
                    Contact: [
                        {
                            max: 32,
                            message:  this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 32}), //'A maximum of 32 characters can be used',
                            trigger: ['change', 'blur']
                        }
                    ],
                    ContactCellPhone: [
                        {min: 6, max: 32,
                            message: this.$t('globalText.mustContainBetween_X_To_Y_Characters', {min: 6, max: 32}), //'Must contain between 6 to 32 characters',
                            trigger: 'blur'},
                        {validator: regExp.checkPhoneNumber, trigger: ['change', 'blur']}
                    ],
                    License: [
                        // {required: true, message: 'Please input the license number', trigger:['change','blur']},
                        {min: 6, max: 32,
                            message: this.$t('globalText.mustContainBetween_X_To_Y_Characters', {min: 6, max: 32}), //'Must contain between 6 to 32 characters',
                            trigger: 'blur'},
                        {validator: regExp.checkLicenseNumber, trigger: ['change', 'blur']}
                    ],
                    LoginName: [
                        {required: true, message: this.$t('tips.drivers.pleaseInputALoginId'), trigger: ['change', 'blur']},
                        {
                            max: 32,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 32}),// 'A maximum of 32 characters can be used ',
                            trigger: ['change', 'blur']
                        }
                    ]

                },
                fontSize: '',
                width: {
                    column1: '132px',
                    column2: '117px',
                    column3: '176px'
                }

            }
        },
        methods: {
            FirstNameInputNoSpace() {

                this.driverInfo.FirstName = regExp.inputNoSpace(this.driverInfo.FirstName);
                // e.target.value = value;
                console.log("after:value =" + this.driverInfo.FirstName);
            },
            lastNameInputNoSpace() {
                this.driverInfo.LastName = regExp.inputNoSpace(this.driverInfo.LastName);
            },
            loginIDInputNoSpace() {
                this.driverInfo.LoginName =regExp.inputNoSpace(this.driverInfo.LoginName);
            },
            getDriverData() {
                if (this.$route.name == "editDriver") {
                    var id = this.$route.query.id;
                    this.isEditMode = true
                    this.$serverApi.driver.getDriveData(id, (data) => {
                        if (data.code == 201) {
                            // this.$customAlert(`Failed to get driver information.`, "Error");
                        } else {
                            this.driverInfo = {
                                Id: data.Id,
                                FirstName: data.FirstName,
                                LastName: data.LastName,
                                CompanyPrefix: data.LoginName.substring(0, data.LoginName.indexOf("_") + 1),
                                LoginName: data.LoginName.substring(data.LoginName.indexOf("_") + 1, data.LoginName.length),
                                License: data.License ? data.License : null,
                                DateJoined: data.DateJoined ? data.DateJoined : null,
                                CellPhone: data.CellPhone ? data.CellPhone : null,
                                Contact: data.Contact ? data.Contact : null,
                                ContactCellPhone: data.ContactCellPhone ? data.ContactCellPhone : null,
                                Remark: data.Remark ? data.Remark : null,
                                LicensePictures: data.LicensePictures ? data.LicensePictures[0] : null,
                                FRPictures: data.FRPictures ? data.FRPictures[0] : null,
                                OtherPictures: data.OtherPictures ? data.OtherPictures[0] : null,
                                Vehicles: data.Vehicles,
                                OldVehicles: data.Vehicles
                            };
                        }

                    })
                }

            },
            selectLicense(imgfiles) {
                this.driverInfo.LicensePicturesRemove = [this.driverInfo.LicensePictures];
                if (imgfiles)
                    this.driverInfo.LicensePictureBase64 = imgfiles;
                else {
                    this.driverInfo.LicensePictureBase64 = null;
                }

            },
            selectDriver(imgfiles) {
                this.driverInfo.OtherPicturesRemove = [this.driverInfo.OtherPictures];
                if (imgfiles)
                    this.driverInfo.DriverPhotoBase64 = imgfiles;
                else {
                    this.driverInfo.DriverPhotoBase64 = null;
                }

            },
            getImagePath(path) {
                if (path)
                    return this.$baseURL + path;
                return null
            },
            getHttpPath(path) {
                if (path)
                    return "https://" + path;
                return null
            },
            onSave() {
                this.$refs['driverform'].validate((valid) => {
                    if (valid) {
                        this.isSaving = true;
                        if (!this.isEditMode) {
                            this.addDriver();
                        } else {
                            this.editDriver();
                        }
                    }
                })
            },
            getEditInfo() {
                var editDriverInfo = {
                    Id: this.driverInfo.Id,
                    FirstName: this.driverInfo.FirstName,
                    LastName: this.driverInfo.LastName,
                    LoginName: this.driverInfo.CompanyPrefix + this.driverInfo.LoginName,
                    License: this.driverInfo.License ? this.driverInfo.License : null,
                    DateJoined: this.driverInfo.DateJoined ? this.driverInfo.DateJoined : null,
                    CellPhone: this.driverInfo.CellPhone ? this.driverInfo.CellPhone : null,
                    Contact: this.driverInfo.Contact ? this.driverInfo.Contact.trim().resetBlank() : null,
                    ContactCellPhone: this.driverInfo.ContactCellPhone ? this.driverInfo.ContactCellPhone : null,
                    Remark: this.driverInfo.Remark ? this.driverInfo.Remark : null,
                }
                editDriverInfo.LicensePicturesRemove = this.driverInfo.LicensePicturesRemove;
                editDriverInfo.OtherPicturesRemove = this.driverInfo.OtherPicturesRemove;
                var addVehicles = getArr1DifferenceFrommArr2(this.driverInfo.Vehicles, this.driverInfo.OldVehicles);
                if (addVehicles)
                    editDriverInfo.addVehicleIds = addVehicles.map((item) => {
                        return item.Id
                    });
                var delVehicles = getArr1DifferenceFrommArr2(this.driverInfo.OldVehicles, this.driverInfo.Vehicles)
                if (delVehicles) {
                    editDriverInfo.delVehicleIds = delVehicles.map((item) => {
                        return item.Id
                    });
                }
                return editDriverInfo;
            },
            showErrorMessage(error) {
                if (error.code === 604) {
                    let driverName = `${this.driverInfo.FirstName} ${this.driverInfo.LastName}`;
                    let msg = this.$t('popUpMsg.drivers.driverName_AlreadyExists', {
                        driverName: driverName
                    }); // `${this.driverInfo.FirstName} ${this.driverInfo.LastName} already exists.`
                    this.$customAlert(msg);
                }
                if (error.code === 605) {
                    let msg = this.$t('popUpMsg.drivers.thisLicenseNumber_LicenseNumber_AlreadyExists', {
                        LicenseNumber: this.driverInfo.License
                    }); // `This license number ${this.driverInfo.License} already exists.`
                    this.$customAlert(msg);
                }
                if (error.code === 606) {
                    let loginId = `${this.driverInfo.CompanyPrefix}${this.driverInfo.LoginName}`;
                    let msg = this.$t('popUpMsg.drivers.thisLoginId_LoginId_AlreadyExists', {
                        loginID: loginId
                    }); // `This login ID ${this.driverInfo.CompanyPrefix}${this.driverInfo.LoginName} already exists.`
                    this.$customAlert(msg);
                }
            },
            addDriver() {
                this.$serverApi.driver.addDriver(this.driverInfo, (data) => {
                    this.updatePictures(data.Id);
                }, (error) => {
                    this.showErrorMessage(error);
                    this.isSaving = false;
                })
            },
            editDriver() {
                var editInfo = this.getEditInfo();
                this.$serverApi.driver.updateDriver(editInfo, (data) => {
                    this.driverInfo.LicensePicturesRemove = [];
                    this.driverInfo.OtherPicturesRemove = [];
                    this.updatePictures(data.Id);
                }, (error) => {
                    this.showErrorMessage(error);
                    this.isSaving = false;
                })
            },
            updatePictures(driverId) {
                var messageCallback = () => {
                    this.$message(
                        {
                            message:this.$t('globalText.savedSuccessfully'),
                            type: 'success'
                        }
                    )
                    this.isSaving = false;
                    this.goBack();
                };
                if (!this.driverInfo.LicensePictureBase64 && !this.driverInfo.DriverPhotoBase64) {
                    messageCallback();
                    return;
                }
                var updatePictureArr = [];
                if (this.driverInfo.LicensePictureBase64) {
                    var picture = {
                        type: "license",
                        base64Source: this.driverInfo.LicensePictureBase64
                    }
                    updatePictureArr.push(picture);
                }
                if (this.driverInfo.DriverPhotoBase64) {
                    var picture = {
                        type: "avatar",
                        base64Source: this.driverInfo.DriverPhotoBase64
                    }
                    updatePictureArr.push(picture);
                }
                this.uploadPicture(driverId, updatePictureArr, 0, messageCallback);


            },
            uploadPicture(driverId, pictureArr, index, callback) {
                if (index >= pictureArr.length) {
                    if (callback)
                        callback();
                    return;
                }
                var picture = pictureArr[index];
                var uploadInfo = {};
                if (picture.type == "license") {
                    uploadInfo = {
                        Id: driverId,
                        LicensePicturesAdd: picture.base64Source
                    }
                } else if (picture.type == "avatar") {
                    uploadInfo = {
                        Id: driverId,
                        OtherPicturesAdd: picture.base64Source
                    }
                }

                this.$serverApi.driver.updateDriver(uploadInfo, () => {
                    this.uploadPicture(driverId, pictureArr, index + 1, callback);
                }, (error) => {
                    this.isSaving = false;
                });
            },
            onCancle() {
                this.goBack();
            },
            goBack() {
                this.$router.go(-1);
            }

        },
        computed: {
            password() {
                return this.$store.getters.driverPassword;
            }
        },
        watch: {
            '$store.getters.screen'() {
                this.fontSize = getComputedStyle(window.document.body)['font-size'];
            },
            '$route.path'() {
                this.getDriverData()
            },
            fontSize(val) {
                switch (val) {
                    case '15px':
                        this.width = {
                            column1: '132px',
                            column2: '117px',
                            column3: '174px'
                        }
                        break;
                    case '16px':
                        this.width = {
                            column1: '139px',
                            column2: '124px',
                            column3: '185px'
                        }
                        break;
                    case '18px':
                        this.width = {
                            column1: '155px',
                            column2: '138px',
                            column3: '207px'
                        }
                        break;

                }
            }
        },
        created() {
            this.getDriverData();
            this.fontSize = getComputedStyle(window.document.body)['font-size'];
        }
    }
</script>

<style lang="stylus" scoped>
    @import "../../../assets/css/management.styl";

    .management-table .text-value-input {
        max-width: 300px;
    }

    .license-area {
        margin: 0 !important;
        padding: 20px 0 20px 0;
    }

    .loginId-input {
        border: 1px solid $color_gray_second;
        border-radius: 4px;
        width: 400px;
        vertical-align middle
    }

    .loginId-input:hover {
        border: 1px solid $color_gray_second;

    }
</style>
<style>
    .date-joined.el-input, .date-joined.el-input__inner {
        width: 100%;
    }

    .loginId-input .el-input-group__prepend {
        padding-left: 10px;
        padding-right: 0;
    }

    .loginId-input .el-input__inner {
        padding-left: 0;
        border: none;
    }


</style>
